import * as types from '../actions/actionTypes';
const Avatars = [
  "654348a084461e994dfa00a3",
  "65434d5dc91bc66cf7de81ae",
  "65434eeecc14c7b0c462cbee",
  "654a373b29a531c3e468283e",
  "654c7d15cb89ba26398ed220",
  "654c7c9bbb3be6ab35d27193",
  "655db2f9e88a425143910559",
  "655db273e88a4251439103da",
  "655de1cebb39d3a4464c1121",
  "65f7c5872cebbb1755b5d287",
  "654e09029b792809cd8a5762",
  "655b4ca11b3c8f493518a60d"
];

const randomItem = Avatars[Math.floor(Math.random() * Avatars.length)];

export const initialState = {
  joinMeetingStatus: false,
  avatarUrlValue: `https://models.readyplayer.me/${randomItem}.glb`,
};

const updateState = (state = initialState, action) => {
  switch (action.type) {
    case types.JOIN_MEETING: {
      return { ...state, joinMeetingStatus: action.payload };
    }
    case types.SET_AVATAR_URL: {
      return { ...state, avatarUrlValue: action.payload };
    }
    default:
      return state;
  }
};

export default updateState;
