export const HOST = 'v3kdlhaldtqo.3dmeet.com';
export const PORT = 4443;
export const MARCUS_SITE_URL = 'https://staging.3dmeet.com';
// TODO: Get list a list of valid integrations from server
export const INTEGRATION_KEYS = {
  'marcus-dev': `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAvv7KxdgrrstNbcY1zL2H
ciSmCeyD6NnJcIw2H52KHeCSLRGvES+ib5x/779+XGdehbDd6InHnndAlrC0kwDZ
jlaMjZ6kNuS53IwoH+i2FxyKKDHCM4iIk2sB950fAOrUxY20u//J3WGJ5tAVC/rN
k5lY0sJ5D06ElKj3JBgNLNXTAahAaIPIJKYmFvD/Of9+O/LbUN93CPXTezFP+asO
kr1BSmwdKNY5b0RJoVvLr1lFej9rMJ2jfFVq1gQikbFObg+eLbGsunXLtp/ZnmEW
88L3xJvnOieGDbhspUVVueeBttf/BVQJYW8YRdSwDljF3S9hLnmSmy/3Sd05jP7c
8+XSrz5Tbj6jDVR+RsugZ7KjWG/jcbEXAQ6SH+NFeBxysS+BzcDRh8DMA7I5ZK6M
SwQzScVev+PjGjmHaZoLD3SAxQfOFsqeT5x/fraTdnbTKyOOqQ128MyAGunbhAXE
Ba13Y0Ak3tC6b01kU1Mgom7vVE6pYspznxysd2wosHb/Nt5wvpYoTOEIy2sHlHOD
2WEAecxR1MdE8HmVTEACoS3G8sd29WT4e+SZEOyGV16eavSR5sGZrXAGDZBItKi3
fYsKc6r4ik9BAioDE1hI06hDrZL+P3izwOyh6b1sRhuKRdcpYMTgnW3h7QwVaW/m
i30KUQ19sZUtsXUUn76KgsMCAwEAAQ==
-----END PUBLIC KEY-----
`,
  'marcus-prod': `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEArHs2AU1ryi5rfJkPduL8
UvvdMvG6EJ5FX8UaDCg7hqEqBAqfXspXAuu1pOizh1COXK92NkVg4+xqQhayFbtb
7tT8QRobieTkhomzqFyATaWmlyUsyiLswITrxH/gZZqYGB8IiqRNUO5qxfe6ksn2
ayalVJuKpnv8Ajcr6OFszLdZXk331P+BJm2RLRSfefEMF6uLvukdSpiySfqZyahx
WzDRKubI4iQGniPM3pwsTsoLX4f/4rotz1O9o1dLobaY1Gsg5qxD0rwQvhhrTxJF
geFgnzAMLcEL8PGaBIVgQJill1Aj6IFkzmBORN52m6Cy2xn4NkVQEkF1qP03TD5q
yAVB/pNniJDgdWzBqN5r8EYLJv8tAopU2Uxr3XxQV5UsAtU9XyJpX6CSYQfpn1fM
80Gl4VlzsRJ7509A0jD7GZSQ/SmeUCSU86KfIy4ml8AsfBPQNteAGVnuWX7svQOy
0S7A1iICRu//aG205XolMYXb0WiKyos5l/8g8//dS1Tw5Yse/D4W5ijGX9Y30+cS
8MowqUkx/kzUJ3boH9TPZFrQ9L27A14kW2xop3PSm/jWDw8/0BuUsy55EBEiI76G
r+2q0w5pNTpgveAmR2Ddzo3FzoZV8/+6zEj5/CO6GJAXqKOPrZcMj45Ywr6jRNm+
BHRHPitw6BFtIKblXtjsKw0CAwEAAQ==
-----END PUBLIC KEY-----
`,
  'fwa-dev': `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA1cIZpMErGmclKdBx+M+w
/28IcZRKQkScFI2Yn6woqUy9X1kIsCXF5V+UzuUngMvuGKf6jeRf1fbokKkSc/OX
2DfYNPDE1MBAjSMb+e8/4N51Q+/uaNdCu6X0/oTAvm1B1kPq5GP6eMgBYNB/gtMf
0rDkmRD0jCuogvT5xqbehdhRq00YQdFKuwefIbNMqAkgpObdeGHq/wqGy80RJbA4
h7kbWUHwFn1a5m4bSlOVgEDElFmM2JJM1fiNLJPZXtonbvMDVCh/c6IiOpPGT9Ut
rp41B02p8J/dig+K44jdJIJ6y36YpUqtLaTcWBk5fME/32f3f2KGjt8DaFBZItT1
h9LxRdVB3S9FXvcydOR/O9Mtdl4htSb79+yCyEKnWHeZ+KiGEb66DWD3TXFAvgHI
Q1ZEIEEyPWXrTDPf6fTa/oGnj2eD6eTk9OYA0EE+UpEMHD4ob2QB+xJoERZAXPse
HoRtsglKvfSF4ghQ/VNrhciWZWUolUgUhr/MjCHLL0NtN6W67T9i0qkOmb6yu1aw
5ea9izhLjsLQq31pFVCEzSeO7AasX5ivvyrsUDiJtK/R/ztRoHokY52z70JYSvxp
boi6J1VBTfYzaeYPdc12nMmH0nctdVPIkwrEWSrlH6BwuwBqE4EFKqGDIL2fcrcz
7dBvQpxFaykAHXB5qSdPlgkCAwEAAQ==
-----END PUBLIC KEY-----
`,
  'fwa-prod': `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAsGHE2WBMNOShj9LPmxAI
KfzpppEwN22CO8+iSJvZcTLImHydLOBocHVOjvV/ATwU8/ylS4sTVswfC/6e32sj
qSUlQsHQjY6xyFHjfhd0TWNVIiHE9OvLENRBZvgpKx87LhvcMUKr0+eYjPNKwxQS
pCoyK96dO6vCUGZeSsxFPMCZGBBy+5n5Z3tGzO+qrsRHZcLJZBNX4NpbmX3NcR6l
jgsoRxcGcJPmj3yaPxyFhmxvRMqyDi4OGddI3l84lVWAFdjl1LLLj/qXSMlV8kaI
ysbHBArEEJE3bBCJvnwK77n5ZKyxKjWl/Mcd/PQ27YGwdOnAugsf8Syoma5m2iAa
Oco83pGLbqxsimjw3ckJPQnGRDB6pIofEdVvBHxERnaN+EtLmvxAqQAZRKQXu24b
RTqGr/bwwAkI8erRxTBoO4BsjPeuq3XFwl2Bm0HOdu8F6W81iNXmcb48l4qFeEzk
kyOU/MTbrqJFb55vJGqgB7qMDCyOjaq09JLbAw6XRnbmCtizWr3DtAJ1FxP8VlJi
eSn7lccV8uC1MShkFeWwONlZ4Gtgo9bbxP5rD0pzAKCFVcmQrXey9/rX0Ql1x98p
2uPe40cSEaRSuu731/OtxjAQNisy/kIcTd8EzgNe0yiAHJk1k5YNn8C3c8WgVND8
lRud7oiksJPHT/pakuYasd8CAwEAAQ==
-----END PUBLIC KEY-----
`,
  'lms-dev': `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAtXeoL1RXFnsNjckzI0WO
2Ccj0Tr7ozUtngiLQZcOOzXLEx9fiYCeKRrLbWhPmqw6wbTeiXBA2UKBVtgp7jpB
UmBzCt1vKrH+EfmTjAJ6Pae6FfDiGaMeYXUdyMPUuedpUSYvuSub/pJplvcmy1H2
laG0o4IwfVDali12z4ajsoB0zzaxFW6hjMJ7YaHiej6bpkBb3cweYV5Jbg8RALx2
TzRLDsAnQHONZrMJU1FtpL/r2GTFs2/mA7X5kSXPuk6q10xVzwEsfLlRGtMPrFLO
PT/JBL/xxojBvo2XzWhF3DkJ1vfnFIOnmD8ussoPfZylcmHhtO8VPKHRnrrXOXL8
66xBgIjW/73sQymh7TuE+D82ORgZCAsNBoweexV1/aVP69oEp37bkNBZ1LrsOnsP
kkKaqsfjFipbrJ6TtBtR2Hwf/orgEnThPgzTHdcMQpNTLS6Apoufl/31iFw+zoOD
7lnTn3OqVlRTpxe1u+gnhjpOEsw8aUrIzrQ5Dpb4tyKqV71i9tJX+wTUKpguOsgc
hox44jbPpNjQb2nC+fSI++On+F+2PMNfJe+yLS7ej8W4iUQn+5rCfRuCVEevIhMN
3upMlMaNMqxLjnxX1Q76oGz+heGthuZEEt972AUSz8zkxRMmMhNHiUXEiTQWx9vv
YjcgnPKaiLpt7AH3cbgBhHUCAwEAAQ==
-----END PUBLIC KEY-----
`,
  'lms-prod': `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAyKFY21yJA1GNEFkWCypg
/xcQ/b4Z4Fi8QAoky0XfTrmcVg4MS0nHDwQ+gSaCDkEG00OKpYEj4K6/IiXwaw8O
Wc57GJs3hr/KvqCtuEfL+pwH5j7WzZEpv8uZbuIdQx8/TJAKbyuwFsPm7BqN6Qmu
lx8LqdIXeEw/ON40X7xYJmP8DWIYtAmRxEI0NCd5gpBHEegytkAgzRpACGJh9lDx
aenceTmo508C7mLywfDxMVjf60v9WvUX6F2OqTN4IEOamVH6Dyy8RvZLIOHnVJgQ
LR6mL0zSqp9V9hx1WTioETSxq/CAF30tLIG8lr4NXXKYwhAPPD1Tok9x4+7rEszG
Rdf3Kpni96LsDDOkqK/ix2h0uMmz7jAKBU9QXt8RsHvh1Gem28Gzv51DJynOFR+U
l5kgey6d8tZwtWfraI0ByqS+bqrU4Gh2RXMjgMLp9UOBi5SJELGSAlufxSjrpUYa
StKo+E2d5UAqHh8wszEgyCQk9qR4reoqv2l3855RYCc+7zhOv7yy/JPTpIda7mmr
eI0uZrBFFamhBiJ+MrTASUyJVMOPpB5mbVO8ekTwn/6Jyr2jyGzEbYCtuqpUuUsb
7ZLW2UQEr/AE8tas8OZhhe9Kex7pcKFGHIE4SZaLfjH5s9zUftWWMAGJ/vv+cWRK
qyT+Kzwnl+TaGFvmtEChLLECAwEAAQ==
-----END PUBLIC KEY-----
`,
'quiklearn': `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAuHg9sAuwSwpZgVF8Dyq6
+38ukbTMhdYUVJMsJ5mM37vsAAZV1CTbN73znGS/5WUl96m5Sm5STlSuhWtAEN8x
peuggMGMbf+pYPwqguziojM1oFuNokEIrp0BaRvJafe2lVYdaFzMU7FaShMJab7N
aSTQXGjB3FPlIFJTbkaNCQ7j0wiG40BoZP7qnZ1CX+2irMoqI8RDU42TcraKuRJJ
LkJTTesjwHtAh8qgO/0FG93zzUZ4MFGATkPN9195RfybysHwukq7VS2zxGoiHb6q
jGeqZBg0rYKL23PMAAZD7w1b0FpvkxdCOA9L/ROCrziGKPWfb8NLAr/POyTpnu78
29FqK+fWajRaS7zJdIG7F4K1XHz51ZmyanLYsjojuhv9mAfR6wNwDxDTncCx3952
kvep4B69zblis442W4QEbY5juV7NP+ow3LP5FknDESLObzrjrYulyAmfCyuXaaVE
oA0HHKyT3MjocuiL3fPGJeBqfFqVUTHGRoabr5jCJMt8XWSajZ3NvahrPPfnaHS5
vnOF5WoUDIPLwrJ6O5LHkqD/EkTGXSETS3A3ozE4boZYHgsksPPIsgZVsfbVg1SB
qyJXdAzzxsdx32O/qJCvBckp0xuKZb87nzu/HvzyEQhSPzcBmC0DWQOnqb7yvFgl
iDZfKGBbFqvH0EjgGkegU4cCAwEAAQ==
-----END PUBLIC KEY-----
`,
};
export const LOG_CHARACTER_APPEARANCE = false;
export const VIEWBOARD_MAP_IMAGE_URI =
  'https://s3.us-east-1.amazonaws.com/d.3dmeet.co/';
export const VIEWBOARD_MAP_IMAGE_PATH = '/map.jpg';
export const VIEWBOARD_SHARE_INSTANCE_CONTENT_WEB_MODAL_MARGIN_TOP = 56;
export const APPEARANCE_JSON_URL =
  'https://scenes.3dmeet.com/avatarAssets/dev/preview/appearanceData.json';
export const ADDRESSABLES_CATALOG_BASE_URL =
  'https://scenes.3dmeet.com/avatarAssets/dev/';

/*
 * @var WEB_SCENES_VERSION
 * Update this value to match the latest webgl asset version. You can
 * find the latest version in the output from the build job:
 * https://fwbuild.ngrok.io/view/Marcus/job/Marcus__unity_lib_webgl
 * The asset version is just a folder on a public s3 bucket. Ideally it
 * is obfuscated enough to remain private while assets are in development
 * And can be used to revert/tightly match 3d world assets to app releases.
 */
export const WEB_SCENES_VERSION = 'b5330fa1308bb68560f82e7200d57274';

export const AVATAR_SDK_CLIENT_ID = 'ewbKaRfdc4j1dPoKavAFt1elXoMvGbLghpn0KlOJ';
export const AVATAR_SDK_SECRET_KEY =
  'EIn1Fm4kX7A4gJdNk9U1n5o8X3bGleuPsRfVLq1WxcuZ8D1CYWk3Kr81knevJqwGxY2Edyi4d3MxxS8d6VRWLXEfOHczexm9jPlgD8nZ1EfkebWuKhrElBO93kyrrdqC';
export const QUIKLEARN_URL = 'https://dev.quiklearn.ai';
export const QUIKLEARN_BACKEND_URL = 'https://ba3w144yue.execute-api.us-west-2.amazonaws.com';
export const MEETING_URL_PREFIX = 'https://app-dev.quiklearn.ai/?token=';
export const LINKS_URL_PREFIX = 'https://links-dev.3dmeet.com/?link=';
