import { useQueryParam, StringParam } from 'use-query-params';
import { setDynamicLink, setCourseTokenValues, setCourseInfoValues, goToDeviceConfig } from './actions';
import { setToken } from 'store/meeting/actions/properties';
import { setMe } from 'store/communication/actions/stateActions';
import { translate } from 'i18n/context/I18nContext';
import { VerifyAndParseToken } from 'utils/communication/Token';
import { logEvent } from 'utils/firebase/analytics';
import { truncateFirebaseAnalyticParameterValue } from 'utils/firebase/utils';
import {
  SET_USER_PERMISSIONS,
  INVALID_TOKEN,
} from 'utils/firebase/analytics.config';
import fetchJsonDataOfCourseInfo from 'utils/communication/DownloadCourseInfo';
import { setLocation } from 'store/world/actions';
import { joinMeeting } from 'store/meeting/actions/meeting';

export const initDynamicLinkHandler = (token, avatarSelection = true) => {
  return async (dispatch, getState) => {

    // Verify token (reject/return to previous state if invalid)
    const verifiedToken = VerifyAndParseToken(token);
    console.log('verifiedToken from linkActions.web.js:', verifiedToken, avatarSelection);

    const courseTokenValues = {
      worldToLoad: verifiedToken.worldToLoad,
      courseContentUrl: verifiedToken.courseContentUrl,
      AITeacherToLoad: verifiedToken.AITeacherToLoad,
      userId: verifiedToken.userId !== undefined ? verifiedToken.userId : 'defaultUserId',
      courseId: verifiedToken.courseId !== undefined ? verifiedToken.courseId : 'defaultCourseId',
      courseProgress: verifiedToken.courseProgress !== undefined ? verifiedToken.courseProgress.progress : 0,
      collectEmails: verifiedToken.collectEmails !== undefined ? verifiedToken.collectEmails : false,
      isPromotional: verifiedToken.isPromotional !== undefined ? verifiedToken.isPromotional : false
    };
    (async () => {
      const { data: resultDataCourseInfo } = await fetchJsonDataOfCourseInfo(verifiedToken.courseContentUrl)
      console.log('resultDataCourseInfo', resultDataCourseInfo);
      dispatch(setCourseInfoValues(resultDataCourseInfo));
    })()
    console.log('worldToLoad from web initDynamicLinkHandler:', courseTokenValues.worldToLoad);
    dispatch(setLocation(courseTokenValues.worldToLoad));

    console.log('courseContentUrl from web initDynamicLinkHandler:', courseTokenValues.courseContentUrl);
    console.log('AITeacherToLoad from web initDynamicLinkHandler:', courseTokenValues.AITeacherToLoad);
    console.log('userId from web initDynamicLinkHandler:', courseTokenValues.userId);
    console.log('courseId from web initDynamicLinkHandler:', courseTokenValues.courseId);
    console.log('courseProgress from web initDynamicLinkHandler:', courseTokenValues.courseProgress);

    dispatch(setCourseTokenValues(courseTokenValues));

    const { error } = verifiedToken;
    if (error) {
      await dispatch(setDynamicLink(undefined));

      if (token) {

        await logEvent(INVALID_TOKEN, {
          error: error,
        });

        console.error(error);
      }
    } else {
      await dispatch(setDynamicLink(token));
      await dispatch(setToken(token));
      if (!avatarSelection)
        dispatch(goToDeviceConfig());

      const { peerId, permissions, roomId, integration } = verifiedToken;
      const displayName = translate('linkActionsWeb.userNum', {
        num: peerId.substring(0, 4),
      });
      const displayNameSet = false;
      const device = {};
      await dispatch(
        setMe({ peerId, displayName, displayNameSet, device, permissions }),
      );

      const permissionsString = permissions.join(' ');
      await logEvent(SET_USER_PERMISSIONS, {
        meeting_id: roomId,
        integration: integration,
        permissions: truncateFirebaseAnalyticParameterValue(permissionsString),
      });
    }
  };
};
