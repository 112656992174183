import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from '@redux-devtools/extension';

import rootReducer from './rootReducer';

const configureStore = () => {
  const middleware = [thunk];
  if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger());
  }

  const enhancer =
    process.env.NODE_ENV === 'production'
      ? applyMiddleware(...middleware)
      : composeWithDevTools(applyMiddleware(...middleware));

  return createStore(rootReducer, enhancer);
};

export default configureStore;
